<template>
  <div>
    <ed-table
    :key="$root.$session.versao"
      strModel="Cadastro/Cid"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      @register="onRegister"
      @edit="onEdit"
      @search="getFuncao"
    />
  </div>
</template>

<script>
import { EdTable } from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdTable },
  mounted() {
    this.getFuncao();
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  created() {},
  data() {
    return {
      loading: false,
      arrayHeaders: [
        {
          text: "Convênio",
          sortable: true,
          value: "strNome",
        },
        {
          text: "Observações",
          sortable: true,
          collapse: 50,
          value: "strObservacao",
        },
        {
          text: "Planos",
          sortable: true,
          collapse: 10,
          collapseLabel: "Visualizar...",
          value: "strPlano",
        },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros:{}
    };
  },
  methods: {
    onRegister() {
      this.$router.push({ name: "cadastro.convenio.register" });
    },

    onEdit(data) {
      this.$router.push({
        name: "cadastro.convenio.edit",
        params: { intId: data.intId },
      });
    },

    getFuncao() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request.get("Cadastro/Convenio", this.formFiltros).then((objResult) => {
        if (objResult.status == 200) {
          this.objPagination = objResult.result;
          this.loading = false;

          objResult.result.data.forEach((objCovenio) => {
            let arrayPlanos = [];

            objCovenio.planos.forEach((element) => {
              arrayPlanos.push(element.strNome);
            });
            let item = {
              intId: objCovenio.intId,
              strNome: objCovenio.strNome,
              strObservacao: objCovenio.strObservacao,
              strPlano: arrayPlanos.join("<br>"),
              _item: objCovenio,
            };

            this.arrayRows.push(item);
          });
        }
      });
    },
  },
};
</script>
